@font-face {
    font-family: "JLinXin";
    src: url('assets/fonts/Jlinxin.woff2') format('woff2'),
}

@font-face {
    font-family: "PingFangSC";
    src: url('assets/fonts/PingFangSC.woff2') format('woff2'),
}

@font-face {
    font-family: "Poppins";
    src: url('assets/fonts/Poppins.woff2') format('woff2'),
}

@font-face {
    font-family: "NotoSansSC";
    src: url('assets/fonts/NotoSansSC.woff2') format('woff2'),
}

/* Global */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font: normal 16px 'NotoSansSC', sans-serif;
    width: 100%;
    height: 100%;
    color: #000;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
}

body, html { overflow-x:hidden; }
