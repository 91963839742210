button {cursor:pointer;border:0;}
button:hover, button:focus, button:active {outline:0;}
a {cursor:pointer;border:0; text-decoration: none;}

.mb24{margin-bottom:24px;}

.content {
    height: 100%;
}

.description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Description */
.description .total {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 103px 0 50px;
}

.description .total img {
    width: 14%;
}

.description .total img.totalPackets {
    width: auto;
    height: 38px;
    margin-right:25px;
}

.description .total img.totalPackets:last-child {
    margin-right:0;
    margin-left:25px;
}

.description .total div {
    width: 70%;
    font-family: 'JLinXin', sans-serif;
    font-size: 4vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #f7f7f7;
}

.description .total div br {
    display: none;
}

.description .total div div {
    display: inline;
    width: 5%;
    height: auto;
    margin: 2px;
    border-radius: 20px;
    background-color: #500000;
}

.description .total div div span {
    font-family: 'Poppins', sans-serif;
    font-size: 150px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 124px;
    letter-spacing: normal;
    text-align: center;
    color: #ffd76c;
    min-width: 103px;
    box-shadow: inset 0px 0px 13px rgba(0,0,0,0.3);
    border-radius: 15px;
    padding: 10px;
    margin: 0 5px;
    display: inline-block;
}

.description .info {
    width: 40%;
}

.description .info, .element .card .card-content .content-info {
    position: relative;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.description .info .title, .element .card .card-content .content-info .title {
    display: table;
    position: absolute;
    width: 166px;
    margin: auto;
    margin-left: -83px;
    top: -10%;
    left: 50%;
    height: 48px;
    border-radius: 24px;
    text-align: center;
    box-shadow: 0 10px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #c10017;
    padding: 0;
}

.description .info .title span {
    display: table-cell;
    vertical-align: middle;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    color: #ffffff;
}

.description .info .count {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: absolute;
    top: 44px;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: 0.4px;
    text-align: center;
    color: #ffd76c;
}

.description .info .count p {
    width: 100%;
}

.description .info .count span {
    color: #ffffff;
}

.description .info img {
    width: 438px;
    height: 130px;
    display: block;
    margin: auto;
}

.description .intro img:not(.mobile) {
    display: block;
    margin: auto;
}

.description .intro {
    padding-top: 78px;
}

.description .intro div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    margin: auto;
}

.description .intro p {
    padding: 0 25px 48px;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    max-width:712px;
}

/* Element */
.element {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 20px;
}

.element .card {
    margin-top: 124px;
    max-width: 1194px;
    width: 100%;
    height: 100%;
    position: relative;
    background:url('https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/img-benefit1-bg@2x_new.png') no-repeat center top / cover;
    border-radius: 7px;
    padding-bottom:48px;
}

.element .card:last-child {
    margin-bottom:124px;
}

.element .card img {
    max-width: 220px;
    width: 100%;
    object-fit:cover;
}

.element .card > img {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    object-fit:initial;
}

.element .card .title {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 56px;
    padding-bottom: 39px;
}

.element .card .title img {
    position: absolute;
    top: -6px;
    right: -5px;
    max-width: 145px;
    width: 152px;
    height: 138px;
    z-index: 2;
    border-radius: 0;
}

.element .card .title img.titleImg {
    max-width: initial;
    width: auto;
    top: initial;
    right: initial;
    height: 50px;
    position: relative;
}

.element .card .title .label {
    position: absolute;
    transform: rotate(45deg);
    top: 25px;
    right: 3px;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #de2026;
    z-index: 2;
}

.element .card .title div:first-of-type {
    position: relative;
    font-family: 'JLinXin', sans-serif;
    font-size: 5vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
}

.element .card .line {
    position: absolute;
    height: 100%;
    width: 100%;
}

.element .card .line:before, .element .card .line:after {
    content: '';
    background: #500000;
    height: 26px;
    width: 26px;
    position: absolute;
    bottom: -4px;
    left: -13px;
    z-index: 1;
    border-radius: 20px;
}

.element .card .line:after {
    left:initial;
    right:-13px;
}

.element > .card > .title:after {
    content:'';
    position:absolute;
    bottom: 0;
    left: 0px;
    margin-left: 11px;
    width:calc(100% - 22px);
    width: -moz-calc(100% - 22px);
    width: -webkit-calc(100% - 22px);
    width: -o-calc(100% - 22px);
    height:1px;
    border-bottom: solid 1px #ffd76c;
}

.element .card .middle-line {
    position: relative;
    width: 12%;
    top: calc(100% - 14px);
    top: -moz-calc(100% - 14px);
    top: -webkit-calc(100% - 14px);
    top: -o-calc(100% - 14px);
    left: 44%;
    border: solid 5px #ffd76c;
    background: #ffd76c;
}

.element .card .card-content {
    position: relative;
    width: 100%;
}

.element .card .card-content .full-width {
    width:100%;
}

.element .card .card-content .nonlogin-qrcode {
    display: block ;
    margin: 0 auto 16px;
    max-width: 58px;
    height: auto;
    border-radius: 0;
}

.element .card:nth-child(3) .card-content > div #content-social {
    padding: 48px 110px 0;
}

.element .card:nth-child(3) .card-content > div #content-social > div {
    position: relative;
}

.element .card .card-content .content-wrapper {
    display: flex;
    align-items: center;
    margin: 0 auto 32px;
}

.element .card .card-content .content-wrapper#content-wrappercontent-wrapper {
    color: #fff;
    justify-content: center;
    margin-bottom: 48px;
    font-size: 20px;
}

.element .card .card-content .content-wrapper div {
    font-size: 42px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: 0.56px;
    text-align: center;
    color: #ffffff;
}

.element .card .card-content .content-wrapper div font {
    color:#fbebaa
}

.element .card .card-content .content-wrapper div:nth-child(2),
.element .card .card-content .content-wrapper div:nth-child(4) {
    max-width: initial;
    width: auto;
}

.element .card:nth-child(2) .card-content .content-wrapper div p.pmobile {
    display:inline-block;
}

.element .card .card-content .content-wrapper span {
    font-size: 42px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.48;
    letter-spacing: normal;
    text-align: center;
    color: #ffd76c;
}

.element .card .card-content .content-wrapper .count p {
    font-size: 20px;
    font-weight:bold;
    color: #ffd76c;
}

.element .card .card-content .content-wrapper .count p span {
    font-size: 20px;
    font-weight:normal;
}

.element .card .card-content .content-wrapper span.friend {
    font-size: 20px;
    font-weight: bold;
}

.element .card .card-content .content-title-img {
    margin-top: 91px;
    width: 70%;
    justify-content: space-between;
}

.element .card .card-content .card-msg {
    font-size:24px;
    text-align: center;
    color:#fff;
    padding-bottom:20px;
}

.element .card .card-content .content-title {
    padding-top: 60px;
    justify-content: center;
    padding-left:15px;
    padding-right:15px;
}

.element .card:nth-child(3) .card-content .content-title {
    max-width: 758px;
    margin: auto;
}

.element .card .card-content .content-title div {
    font-size:42px;
    line-height:62px;
}

.element .card .card-content .content-info .title span {
    display: table-cell;
    vertical-align: middle;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    color: #ffffff;
}

.element .card .card-content #content-social {
    padding-top: 48px;
    align-items: flex-start;
}

.element .card .card-content .oneChance {
    color: #fff;
    padding: 44px 15px 2px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}

.element .card .card-content .content-wish {
    padding-top: 32px;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.element .card .card-content .content-wish div:nth-child(2) {
    margin-top: -36px;
}

.element .card .card-content .content-wish span {
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: 0.56px;
    text-align: center;
    color: #ffd76c;
}

.element .card .card-content .content-info .count {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: absolute;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: 0.4px;
    text-align: center;
    color: #ffd76c;
}

.element .card:first-of-type .card-content .content-info .count {
    top: 44px;
}

.element .card .card-content .content-info .count span  {
    font-size: 20px;
    font-weight:bold;
}

.element .card .card-content .content-info .count p {
    width: 100%;
}

.element .card .card-content .content-info .count span {
    color: #ffffff;
}

.element .card .card-content .content-info img {
    max-width: 300px;
    width: 100%;
    display: block;
    margin: auto;
    object-fit: contain;
}

.element .card .card-content #content-social {
    width: 100%;
    position:relative;
}

.element .card .card-content #content-social .line:after, .element .card .card-content #content-social .line:before, .element .card .card-content #content-social .line .subline:before  {
    content:'';
    width: 5px;
    height:64px;
    background:#ffd76c;
    position:absolute;
    top: 0;
    left: 25%;
    margin-left: -2.5px;
}

.element .card .card-content #content-social .line:before{
    top: 18%;
}
.element .card .card-content #content-social .line:after{
    top: 32%;
    left: 50%;
}
.element .card .card-content #content-social .line .subline:before{
    top: 48%;
    left: 75%;
}

.element .card .card-content #content-social .step {
    width: 100%;
    padding: 0 5% 0;
    border-collapse: collapse;
    position:relative;
}

.element .card:nth-child(3) .card-content #content-social .step td:last-child .image-single {
    display: block;
    width: 101px !important;
    margin: 0 auto;
    position:relative;
}

.element .card:nth-child(3) .card-content #content-social .step td:last-child .image-single img {
    display: block;
    width: 101px !important;
    border-radius: 0;
    box-shadow: 3px 4px 4px rgba(0,0,0,0.3);
}

.benefit-angpow {
    position:relative;
}

.carousel-content .benefit-angpow {
    padding: 0px 0 36px !important;
    width: 103px;
    margin: 0 auto;
}

.benefit-angpow:before {
    content: 'x1';
    width: 30px;
    height: 30px;
    position: absolute;
    right: -15px;
    top: -8px;
    background: #ffd76c;
    border-radius: 20px;
    line-height: 27px;
    font-size: 16px;
    font-weight: 600;
    color: #de2026;
}

.element .card .card-content #content-social .step tr {
    border: none;
}

.element .card .card-content #content-social .step td {
    width: 15%;
    padding: 0 18px;
    border-left: 1px solid #ffd76c;
}

.element .card .card-content #content-social .step td div {
    font-size: 20px;
}

.element .card .card-content #content-social .step td:first-of-type {
    border-left: none;
}

.element .card .card-content #content-social .step td .image-multiple, .image-single {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.element .card .card-content #content-social .step td .image-multiple img {
    width: 25%;
    margin: 2%;
}

.element .card .card-content #content-social .step td .image-single img {
    width: 55%;
}

.element .card .card-content #content-social .step td div {
    display: inline-block;
}

.element .card .card-content #content-social tr:first-of-type td {
    font-family: "NotoSansSC", sans-serif;
    padding-bottom: 24px;
    font-size: 28px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: center;
    color: #ffd76c;
}

.element .card .card-content #content-social button, .carousel-content button {
    width: 88%;
    border-radius: 24px;
    background-image: linear-gradient(to bottom, #fbebaa, #ffb02c);
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: center;
    color: #de2026;
    padding: 7px 15px;
}

.element .card .card-content #content-social tr:nth-child(2) td {
    vertical-align: top;
    padding-bottom: 32px;
    font-size: 1vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.element .card .card-content #content-social tr:nth-child(2) img {
    display: inline;
}

.element .card .card-content .content-button {
    padding: 0;
    width: 65%;
}

.element .card .card-content .content-button div {
    line-height: 1;
    width: 100%;
    justify-content: space-between;
}

.element .card .card-content .content-button button {
    margin: 0 2%;
    max-width: 208px;
    width: 100%;
    height: 48px;
    border-radius: 24px;
    background-image: linear-gradient(to bottom, #fbebaa, #ffb02c);
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: center;
    color: #de2026;
    border:0;
}

.element .card .card-content .content-button button:hover {
    outline: 0;
    box-shadow: 0 10px 6px 0 rgba(0, 0, 0, 0.2);
}

.element .card .card-content #angpow {
    width: 79px;
    border-radius:0;
    display: inline-block;
    margin-left: 16px;
    margin-right: 10px;
    vertical-align: -33px;
}

.element .card .card-content #diamond {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: initial;
    width: 200px;
}

.element .card .card-content #wordings {
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.32px;
    text-align: center;
    color: #ffd76c;
}

.element .card .card-content #message {
    display: block;
    padding: 0px 0 48px;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: 0.4px;
    color: #ffb500;
    text-align: center;
}

/* Rules */
.rules {
    width: 100%;
    position: relative;
    background: #6f0002 url(https://cdn.dootech.io/doo-prime-cny/Login%20Version/PNG/img-rules@2x_new10.png) no-repeat center bottom / contain;
}

.rules img {
    width: 100%;
}

.rules .text {
    max-width: 1381px;
    width: 100%;
    margin: 0 auto;
    padding: 118px 20px 119px;
}
.rules .textWidth {
    max-width: 640px;
    width: 100%;
}

.rules .text h2 {
    font-size: 38px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #ffd76c;
    margin-bottom: 32px;
}

.rules .text p {
    padding-bottom: 13px;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.rules .text p span {
    font-weight: bold;
    color: #ffd76c;
}

.rules .text p span:last-child {
    color:#fff;
}

.rules .text .logo {
    padding-bottom: 31px;
}

.rules .text .logo img {
    width: auto;
    height: 43px;
    display: inline-block;
    margin-right: 25px;
}

.rules .table {
    border-collapse: collapse;
}

.rules .table tr {
    border-bottom: solid 1px #de2026;
}

.rules .table tr:last-child {
    border-bottom: 0;
}

.rules .table td {
    vertical-align: top;
    padding-top: 25px;
    padding-bottom: 25px;
}

.rules .table td > span {
    line-height: 43px;
}

.rules .table td > span img {
    width: 25px;
    height: 26px;
}

.rules .table td:first-of-type span {
    padding-right: 24px;
    font-family: 'JLinXin', sans-serif;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #ffd76c;
}

.rules .table td:last-of-type span {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.mobile {
    display: none;
}

@media only screen and (max-width: 1280px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .description .intro .desktop {
        display: none !important;
    }

    .description .intro {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .description .intro div.images{
        align-items: flex-end;
    }

    .description .intro div.images img:not(.mobile){
        margin-bottom: -8px;
        width: 99px;
    }

    .description .intro img:not(.desktop, #divider) {
        display: inline-block !important;
    }

    .description .total {
        font-size: 28px;
        padding: 0 0 68px;
        justify-content: center;
    }

    .description .total div {
        display: block;
        width: 100%;
    }

    .description .total div br {
        display: block;
    }

    .description .total div div {
        display: inline-block;
        min-width: 54px;
        width: auto;
        margin: 16px 3px 20px;
        position: relative;
    }

    .description .total div div::after {
        content: "";
        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;
        z-index: -1;
        background-color: #500000;
        border-radius: 20px;
    }

    .description .total div div span {
        font-size: 78px;
        font-weight: bold;
        background: #500000;
        line-height: 62px;
        padding: 5px;
        min-width: 54px;
    }

    .description .total img.totalPackets {
        height: 28px;
        margin-right: 0;
    }

    .description .total img.totalPackets:last-child {
        margin-left: 0;
    }

    .description .info {
        width: 80%;
        margin: 0 0 68px;
    }

    .element .card .card-content .content-info .title {
        top: 0;
        transform: translate(-50%, -32%);
        width: 44%;
        height: 38px;
        left: 50%;
        padding: 0;
    }

    .description .info .title {
        height: 38px;
        width: 131px;
        margin: auto auto auto -65px;
        top: -19px;    
    }

    .description .info .count {
        top: 54%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .description .info img {
        max-width: 290px;
        height: 100px;
    }

    .description .info .count,
    .description .info .title span {
        font-size: 14px;
        font-weight: bold;
    }

    .description .intro {
        padding: 0 40px 70px;
    }

    .description .intro p {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.86;
        white-space: nowrap;
        padding: 0;
    }

    .description .intro div:first-of-type,
    .description .intro div:nth-of-type(2) {
        padding: 0;
        margin: 0 0 20px;
    }

    .description .intro > img {
        width: 220px !important;
        height: 22px !important;
    }




    .element {
        padding: 0 20px;
    }

    .element .card .card-content .content-title-img #diamond {
        display: none;
    }

    .element .card .title img {
        top: -2.5px;
        right: -2.5px;
        width: 87px;
        height: 79px;
        border-radius: 0;
    }

    .element .card .title:after {
        margin-left: 7px;
        width: calc(100% - 14px);
        width: -moz-calc(100% - 14px);
        width: -webkit-calc(100% - 14px);
        width: -o-calc(100% - 14px);
    }

    .element .card .title img.titleImg {
        height: 24px;
        position: relative;
        object-fit: contain;
    }

    .element .card:first-of-type .title img.titleImg {
        height: 53px;
    }

    .element .card .line:before, .element .card .line:after {
        bottom: -10px;
        height: 20px;
        width: 20px;
    }

    .element .card .title .label {
        font-size: 14px;
        font-weight: bold;
        top: 18px;
        right: 5px;
    }

    .element .card .card-content {
        padding: 20px 24px 32px;
    }

    .element .card .card-content .content-wrapper span {
        font-size:16px;
    }

    .element .card .card-content .content-wrapper div font {
        display: block;
    }

    .element .card .card-content .content-wrapper.content-title-img > div:last-child span {
        display: block;
    }

    .element .card .card-content .content-wrapper.content-title-img > div:last-child img#angpow {
        display: inline-block;
        margin: 0;
        vertical-align: text-top;
        margin-right: 5px;
        width: 27px;
    }

    .element .card .card-content .content-wrapper.content-title-img > div:last-child font {
        display: inline-block;
    }

    .element .card:nth-child(2) .card-content .content-wrapper div p.pmobile {
        display:block;
    }

    .element .card .card-content .content-wrapper .count p {
        font-size: 14px;
    }

    .element .card .card-content .content-wrapper .count p span {
        font-size: 14px;
    }

    .element .card .card-content .content-title-img,
    .element .card .card-content .content-title {
        width: 100%;
        margin: 0;
        padding: 0 0 20px;
    }

    .element .card .card-content .content-title {
        padding: 0 0 32px;
    }

    .element .card .card-content .content-title-img{
        max-width: 300px;
        width: 100%;
        margin: 0 auto;
    }

    .element .card .card-content .content-title-img div,
    .element .card .card-content .content-title div {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.5;
        max-width: 245px;
    }

    .element .card:last-child .card-content .content-title div {
        max-width: 224px;
    }

    .element .card .card-content .content-wish {
        width: 100%;
        margin: 0 0 24px;
        padding: 0;
    }

    .element .card .card-content .content-wish div:nth-child(2) {
        margin-top: -32px;
    }

    .element .card .card-content .content-wish span {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: normal;
    }

    .element .card:nth-child(2) .card-content .content-wish span {
        width: 200px;
        display: inline-block;
    }

    .element .card .card-content .content-wish img {
        width: 49px;
        height: 22px;
    }

    .element .card .card-content .content-info .title span {
        line-height: 1.8;
        font-size: 12px;
    }

    .element .card .card-content .content-info .title::after {
        display: none;
    }

    .element .card {
        width: 100%;
        max-width: 80%;
        margin: 0 0 62px;
        padding: 0;
    }

    .element .card:last-child {
        margin-bottom:60px;
    }

    .element .card .title {
        height: auto;
        padding: 29px 0;
    }

    .element .card:first-of-type .title {
        padding: 20px 0;
    }

    .element .card .middle-line {
        width: 57px;
        border-block-width: 3px;
        top: calc(100% - 3px);
        top: -moz-calc(100% - 3px);
        top: -webkit-calc(100% - 3px);
        top: -o-calc(100% - 3px);
        left: 50%;
        margin-left: -28px;
    }

    .element .card .card-content .content-info {
        width: 100%;
        margin: 0 0 28px;
        padding: 0 30px;
    }

    .element .card .card-content .content-info .title {
        height: 38px;
        top: -13px;
        width: 99px;
        margin-left: -50px;
        transform: initial;
    }

    .element .card .card-content .content-info .count {
        font-size: 14px;
        font-weight: bold;
        top: 48%;
        transform: translate(0, -50%);
    }
    
    .element .card:first-of-type .card-content .content-info .count {
        top: 55%;
    }

    .element .card .card-content .content-info .count span {
        font-size: 14px;
    }

    .element .card .card-content .content-button div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .element .card .card-content .content-info img {
        height: 124px;
    }
    
    .element .card:not(:first-child) .card-content .content-info img {
        max-width: 220px;
        height: 60px;
    }

    .element .card .card-content .content-button {
        padding: 0;
        width: 100%;
    }

    .element .card .card-content #message {
        font-size: 14px;
        font-weight: bold;
        padding: 0;
    }

    .element .card .card-content .card-msg {
        font-size: 16px;
    }

    .element .card .card-content .content-button button {
        font-family: "NotoSansSC", sans-serif;
        font-size: 16px;
        font-weight: bold;
        min-width: 150px;
        width: auto;
        margin: 0;
        padding: 0 27px;
        height: 40px;
    }

    .element .card .card-content .content-button button:first-of-type {
        margin-bottom: 20px;
    }

    .carousel .slide {
        background-color: transparent;
    }

    .carousel.carousel-slider .control-arrow, .carousel .control-arrow {
        opacity: 1;
    }

    .control-prev:before {
        border-right: none !important;
        content: url('https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-icon-benefit3-arrow-left.png') !important;
    }

    [dir='rtl'] .control-prev:before {
        content: url('https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-icon-benefit3-arrow-left.png') !important;
    }

    .control-next:before {
        border-left: none !important;
        content: url('https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-icon-benefit3-arrow.png') !important;
    }

    [dir='rtl'] .control-next:before {
        content: url('https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-icon-benefit3-arrow.png') !important;
    }

    .element .card .card-content .qr-code {
        margin: auto;
        width: 100%;
        max-width: 180px;
    }

    .element .card:nth-child(3) .card-content>div {
        padding: 0;
    }

    .element .card .card-content #content-social-carousel {
        width: 100%;
        height: auto;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
    }

    .element .card .card-content .oneChance {
        font-size:14px;
        padding: 0px 15px 20px;
    }

    .element .card .card-content .carousel-content {
        width: 90%;
        margin: auto;
    }

    .element .card .card-content .carousel-content div:first-of-type {
        padding-bottom: 12px;
        font-size: 24px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.46;
        letter-spacing: normal;
        text-align: center;
        color: #ffd76c;
    }

    .element .card .card-content .carousel-content div:last-of-type {
        padding: 24px 0 36px;
    }

    .element .card .card-content .carousel-content button {
        width: 50%;
    }

    .element .card .card-content .carousel-content #img-social {
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 40px;
    }
    .element .card .card-content .carousel-content #img-social img {
        width: 20%;
        margin: 2%;
    }

    .element .card .card-content .carousel-content #img-phone {
        width: auto;
    }

    .element .card .card-content .carousel-content #img-angpow {
        width: auto;
        box-shadow: 3px 4px 4px rgba(0,0,0,0.3);
        border-radius:0;
    }

    .element .card .card-content .carousel-content #qr-code-btn {
        word-break: keep-all;
        width: fit-content;
        font-size: 16px;
    }

    .carousel .control-dots {
        position: static;
        margin: 0 0 40px;
    }

    .carousel .control-dots .dot {
        width: 5px;
        height: 5px;
        background: #ffd76c;
        opacity: 0.5;
        box-shadow: none;
        margin: 0 10px;
        border-radius: 5px;
        outline: none;
        transition: 0.3s all;
    }

    .carousel .control-dots .dot.selected {
        width: 20px;
    }

    .rules {
        margin-top: 0;
        background: url("https://cdn.dootech.io/doo-prime-cny/Mobile/PNG/m-img-rules@2x.png") no-repeat scroll;
        background-position: center bottom;
        background-size: cover;
    }

    .rules::after {
        content: "";
        width: 167px;
        height: 45px;
        position: absolute;
        right: 0;
        top: 51.5px;
        background: url("https://cdn.dootech.io/doo-prime-cny/Mobile/SVG/m-icon-rules-element2.svg") no-repeat scroll;
    }

    .rules > img.mobile {
        display: none;
    }

    .rules .text {
        position: static;
        width: 100%;
        padding: 60px 48px 305px 40px;
    }

    .rules .text h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 24px;
    }

    .rules .text p {
        display: flex;
        flex-direction: column;
        font-size: 13px;
        margin: 0 0 15px;
        padding: 0;
    }

    .rules .text .logo {
        display: flex;
        flex-wrap: nowrap;
        padding-bottom: 60px;
    }

    .rules .text .logo img {
        height: 34px;
        margin: 0 16px 0 0;
    }

    .rules .table td:first-child > span {
        line-height: 38px !important;
    }
    
    .rules .table td > span img {
        width: 17px;
        height: 20px;
    }

    .rules .table tr td {
        padding: 20px 0;
    }

    .rules .table tr td:first-of-type span {
        font-size: 20px;
        font-weight: normal;
        margin-right: 20px;
        padding: 0;
    }

    .rules .table tr:first-of-type td {
        padding-top: 0;
    }

    .rules .table tr td:last-of-type span {
        font-size: 14px;
        font-weight: normal;
    }

    .rules .table tr:last-of-type {
        border-bottom: none;
    }
}

@media only screen and (max-width: 560px) {
    /* .element .card .title img.titleImg {
        height: 18px;
    } */

    .element .card {
        max-width: 100%;
    }
}
