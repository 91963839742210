/* Homepage */
.layout {
    position: relative;
    background-color: #500000;
    height: 100%;
}

/* Banner */
.header-wrapper {
    background: #6f0002;
    background: -moz-linear-gradient(top,  #6f0002 86%, #500000 87%);
    background: -webkit-linear-gradient(top,  #6f0002 86%,#500000 87%);
    background: linear-gradient(to bottom,  #6f0002 86%,#500000 87%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6f0002', endColorstr='#500000',GradientType=0 );
    position:relative;
}

.header-wrapper:before {
    content:'';
    width:50%;
    height:100%;
    position:absolute;
    right:0;
    top:0;
    background: #96000a;
    background: -moz-linear-gradient(top,  #96000a 86%, #500000 87%);
    background: -webkit-linear-gradient(top,  #96000a 86%,#500000 87%);
    background: linear-gradient(to bottom,  #96000a 86%,#500000 87%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#96000a', endColorstr='#500000',GradientType=0 );
    z-index:0;
}

.header {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.header > img {
    width: 100%;
    object-fit: cover;
    height: 1073px;
}

.header .header-desc {
    display: block;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    margin-left: -682px;
    top: 342px;
}

.header .header-desc figure img {
    max-width: 840px;
}

.header .header-desc .maintitle {
    width: 57%;
}

.header .header-desc .subtitle {
    position: relative;
    margin: 0 0 16px;
}

.header .header-desc .subtitle.shine {
    max-width: 565px;
}

.header .header-desc .subtitle.shine img {
    max-width: 565px;
}

.header .header-desc .subtitle.shine:before {
    content:'';
    background:url(https://cdn.dootech.io/doo-prime-cny/Share/img-banner-shine@2x_new.png) no-repeat center top;
    position: absolute;
    width: 304px;
    height: 102px;
    right: 9px;
    top: -20px;
}

.header .header-desc .decor {
    width: 21%;
    margin-bottom: 40px;
}

.header .header-desc p.duration {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: left;
    color: #ffd76c;
}

.header .duration span {
    color: #ffffff;
}

/* Footer */
.footer {
    background:#fff;
    padding: 0 20px;
}
.footer .count {
    width: 100%;
    max-width: 1381px;
    margin: 0 auto;
    min-height: 118px;
    height: auto;
    background-color: #ffffff;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer .count .count-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width:calc(100% - 400px);
    width: -moz-calc(100% - 400px);
    width: -webkit-calc(100% - 400px);
    width: -o-calc(100% - 400px);
}

.footer .count img {
    padding-right: 2%;
}

.footer .count #number {
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #d91d22;
}

.footer .count #number span {
    font-family: 'PingFangSC', sans-serif !important;
    font-style: normal;
}

.footer .count #desc {
    padding-left: 8px;
    padding-right: 2%;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #131329;
    padding-right: 50px;
}

.footer .count button {
    width: 12%;
    height: 47px;
    border: solid 1px #d91d22;
}

.footer .count #open_acc {
    margin-right: 12px;
    background-color: #d91d22;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    width: 224px;
}

.footer .count #demo_acc {
    background-color: transparent;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: #d91d22;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    width: 224px;
}

.footer .count #demo_acc a {
    text-decoration: none;
    color: #d91d22;
}

.footer .count #open_acc a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.95);
}

.footer .links {
    background-color: #000018;
    position: relative;
    padding: 0 20px;
    margin-left: -20px;
    margin-right: -20px;
}

.footer .links #policy-wrapper-bg {
    background: #131329;
    margin: 0 -20px;
}

.footer .links #policy-wrapper {
    width: 100%;
    background-color: #131329;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1381px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer .links #policy {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 41px;
    height: auto;
}

.footer .links #policy span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.95);
}

.footer .links #sitemap span {
    font-family: 'PingFangSC', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: right;
    color: rgba(255, 255, 255, 0.55);
}

.footer .links #navigation {
    padding: 40px 0 49px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1381px;
    margin: 0 auto;
}

.footer .links #navigation #social {
    padding-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer .links #navigation span {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.95);
}

.footer .links #navigation .list-title {
    padding-top: 14px !important;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.37);
}

.footer .links #navigation li {
    padding-top: 9px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.95);
    list-style-type: none;
}

.footer .links #navigation a {
    color: rgba(255, 255, 255, 0.95);
    text-decoration: none;
    cursor: pointer;
    margin-right: 11px;
}

.footer .links #navigation a:last-child {
    margin-right:0;
}

.footer .links #navigation .wechat-qr {
    display: block;
    text-align: right;
}

.footer .links #help-center {
    padding-bottom: 48px;
    border-top: 1px solid #ffffff;
    max-width: 1381px;
    margin: 0 auto;
}

.footer .links #help-center h4 {
    padding-top: 16px;
    font-family: 'PingFangSC', sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.95);
}

.footer .links #help-center #country {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #16a0ff;
}

.footer .links #help-center p {
    padding: 8px 0 8px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.95);
}

.footer .links #help-center .list-wrapper:first-of-type {
    margin-top: 8px;
}

.footer .links #help-center .list {
    padding-top: 14px;
    padding-bottom: 14px;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.footer .links #help-center .list span {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.95);
}

.footer .links #help-center .text {
    margin: 10px auto 10px;
    color: #85868c;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.5;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.footer .links #help-center .text div {
    width: 45%;
}

.footer .links #help-center .text div span {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
}

.footer .links #help-center .text a {
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
    color: #ffffff;
}

.footer .links #help-center .list.active {
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.95);
}

.footer .copyright {
    min-height: 41px;
    height: auto;
    background-color: #131329;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -20px;
    margin-right: -20px;
    padding: 10px 0;
}

.footer .copyright span {
    width: 90%;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: rgba(255, 255, 255, 0.95);
}

.footer .copyright.icp {
    padding: 6px 0;
    min-height: auto;
    border-top: solid 1px rgba(255, 255, 255, 0.04);
}

.footer .copyright .icp span {
    font-size: 14px;
    line-height: normal;
}

.mobile {
    display: none;
}

@media only screen and (max-width: 1370px) and (min-width: 1281px) {
    .header .header-desc {
        margin-left: -621px;
    }
}

@media only screen and (max-width: 1280px) {
    .desktop {
        display: none !important;
    }

    .mobile {
        display: block;
    }

    .header {
        position: relative;
        padding: 0 0 56.5px;
    }

    .header-wrapper {
        background: #6f0002;
        background: -moz-linear-gradient(top,  #6f0002 87%, #500000 88%);
        background: -webkit-linear-gradient(top,  #6f0002 87%,#500000 88%);
        background: linear-gradient(to bottom,  #6f0002 87%,#500000 88%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6f0002', endColorstr='#500000',GradientType=0 );
    }

    .header-wrapper:before {
        background: #88000b ;
        background: -moz-linear-gradient(top,  #88000b  87%, #500000 88%);
        background: -webkit-linear-gradient(top,  #88000b  87%,#500000 88%);
        background: linear-gradient(to bottom,  #88000b  87%,#500000 88%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#88000b ', endColorstr='#500000',GradientType=0 );
    }

    .header > img {
        object-fit: contain;
        height: auto;
        max-height: 675px;
    }

    .header .header-desc {
        left: 50%;
        margin-left: -151px;
        top: 109px;
    }

    .header .header-desc figure {
        text-align: center;
    }

    .header .header-desc figure img {
        max-width: 303px;
        width: 100%;
    }

    .header .header-desc .maintitle {
        width: 100%;
    }

    .header .header-desc .subtitle {
        width: 100%;
        margin: 0;
    }

    .header .header-desc .decor {
        display: none;
    }

    .header .header-desc p.duration {
        font-size: 13px;
        font-weight: bold;
        text-align: center;
        margin: 40px 0 16px;
    }

    .header .duration {
        font-size: 13px;
        font-weight: bold;
        text-align: center;
        margin: 26vh 0 16px;
        position: absolute;
        bottom: 59px;
        left: 50%;
        margin-left: -61px;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: normal;
        color: #ffd76c;
    }

    .footer .count {
        flex-direction: initial;
        display: initial;
        align-items: initial;
        justify-content: initial;
        padding: 40px 57px;
        margin: 0 -20px;
        width: initial;
        display: block;
    }

    .footer .count .count-content {
        display: initial;
        align-items: initial;
        justify-content: initial;
        width: initial;
        display:block;
        text-align: center;
    }

    .footer .count #number, .footer .count #desc {
        text-align:center;
        padding-right:0;
    }

    .footer .count #number {
        font-weight: 600;
    }
    
    .footer .count #desc {
        font-size:18px;
        line-height:22px;
        padding-bottom: 34px;
    }

    .footer .count img {
        display: none;
    }

    .footer .count button {
        width: 80%;
        margin: 0 auto 24px;
        display: block;
        max-width: 260px;
        width: 100% !important;
    }

    .footer .count button:last-child {
        margin-bottom:0;
    }

    .footer .count #open_acc {
        margin: 0 auto 24px;
        display: block;
    }

    .footer .links {
        background-color: #000018;
        padding-top: 40px;
    }

    .footer .links #policy-wrapper {
        background-color: #090920;
        margin-left: -20px;
        margin-right: -20px;
        width: initial;
    }

    .footer .links #policy {
        padding: 8px 0 8px;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }

    .footer .links #policy span {
        display: inline-block;
        line-height: 36px;
        color:rgba (255,255,255, 0.55);
    }

    .footer .links #navigation {
        flex-direction: column;
        padding: 40px 0 32px;
    }

    .footer .links #navigation .wechat-qr {
        justify-content: flex-end !important;
        margin-top: 0px !important;
    }

    .footer .links #navigation .wechat-qr img {
        margin-top:24px;
    }

    .footer .links #navigation #logo {
        display: block !important;
        margin: auto auto 40px;
    }

    .footer .links #navigation .menu {
        width: 100%;
        cursor: pointer;
    }

    .footer .links #navigation .menu-title {
        padding: 18px 0 18px !important;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    }

    .footer .links #navigation .menu-title span {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: rgba(255, 255, 255, 0.95);
    }

    .footer .links #navigation .social {
        margin: 40px auto 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .footer .links #help-center h4 {
        font-size: 12px;
    }

    .footer .links #help-center .text {
        flex-direction: column;
    }

    .footer .links #help-center .text div {
        width: 100%;
    }

    .footer .links #help-center .text div:last-of-type {
        padding-top: 16px;
        width: 100%;
    }

    .footer .menu .submenu-title span {
        font-size: 12px !important;
        font-weight: normal !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.5 !important;
        letter-spacing: normal !important;
        text-align: left !important;
        color: rgba(255, 255, 255, 0.37) !important;
    }

    .footer .menu .submenu {
        margin-top: 2%;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: left;
        color: rgba(255, 255, 255, 0.95);
    }

    .footer .menu .submenu a {
        text-decoration: none;
    }
}

@media only screen and (max-width: 550px) {
    .footer .copyright span {
        max-width: 402px;
        width:100%;
    }
}

@media only screen and (max-width: 530px) {
    .header > img {
        object-fit: initial;
        height: 675px;
        width: 100%;
    }
}
