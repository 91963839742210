/* Navbar */
.navbar {
    height: 120px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 24, 0.7);
    z-index: 5;
}

.navbar .login {
    height: 40px;
    background-color: #131721;
    width: 100%;
}

.navbar .greeting {
    margin-right:10px;
}

.navbar .logout {
    color: rgba(74, 150, 255, 0.95) !important;
    margin-left: 10px;
    cursor: pointer;
}

.navbar .top-wrapper {
    color:rgba(255, 255, 255, 0.25);
}

.navbar .login a {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar .logo button a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: rgba(255, 255, 255, 0.95);
}

.navbar .login .desktop {
    max-width: 1381px;
    padding: 8px 15px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.navbar .login .desktop a img {
    margin-right: 5px;
}

.navbar .login .desktop span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.95);
}

.navbar .logo {
    margin: auto;
    padding: 15px;
    max-width: 1381px;
    width: 100%;
}

.navbar .logo img {
    padding-top: 5px;
}

.navbar .logo button {
    float: right;
    width: 128px;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #fefefe;
    border-radius: 5px;
    border-color: #d91d22;
    background-color: #d91d22;
}

@media only screen and (max-width: 1280px) {
    .navbar {
        height: 56px;
        background-color: #500000;
    }

    .navbar .login {
        height: 56px;
    }

    .navbar .login .mobile {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: auto;
    }

    .navbar .login .mobile div {
        width: initial !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffffff;
    }

    .navbar .login .mobile div span {
            white-space: nowrap;
    }

    .navbar .login .mobile div > a {
        margin-right: 18.7px;
    }

    .navbar .login .mobile button {
        width: 89px;
        height: 32px;
        margin-left: 0;
        border-radius: 2px;
        border: #d91d22;
        background-color: #d91d22;
    }

    .navbar .login .mobile a {
        display: block;
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0;
        letter-spacing: normal;
        text-align: center;
        color: rgba(255, 255, 255, 0.95);
        padding: 16px 0;
    }

    .navbar .logo {
        display: none;
    }
}
