#homepage {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    vertical-align: top;
}

#background {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
}

#qr {
    position: absolute;
    width: 33%;
    top: 59.3%;
    left: 33.5%;
}

#qr img {
    width: 100%;
    height: 90%;
}

.downloadbutton {
    background-image: linear-gradient(to bottom, #fbebaa, #ffb02c);
    height: 48px;
    left: 50%;
    border-radius: 24px;
    border: 0;
    font-family: "NotoSansSC", sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.45;
    color: #de2026;
    padding: 12px 30px 12px;
    display: block;
    margin: 31px auto 31px;
}
