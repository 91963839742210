.btn {
    text-align: center;
    position: absolute;
    top: 30%;
    left: 40%;
    zoom: 1.5;
}

.button {
    border-radius: 10px;
    background-color: grey;
    outline: none;
}

.button:hover {
    background-color: white;
}

.modal {
    position: fixed;
    z-index: 30;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    padding: 0 15px;
    overflow-y:auto;
}

.modal_content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    padding: 20px;
    display:flex;
}


.modal_wrapper {
    position: relative;
    top: 0;
    margin: auto;
    height: 594px;
}

.modal_wrapper img {
    position: relative;
    width: auto;
    height: 594px;
    display: block;
    margin: 0 auto;
}

.content_wrapper {
    position: absolute;
    top: 40%;
    width: 100%;
    font-family: "NotoSansSC", sans-serif;
    text-align: center;
    padding: 32px 0;
}

.content_wrapper label { 
    display: block;
}

.content_wrapper label::-webkit-scrollbar {
    width: 3px;
    height: 70px;
}

.content_wrapper label::-webkit-scrollbar-track {
    background: #500000;
}

.content_wrapper label::-webkit-scrollbar-thumb {
    background: #f58c49;
    border: 1px solid transparent;
    border-radius: 3px;
}

.openAnimated {
    width:400px;
    height:400px;
}

.model_content_head {
    font-size: 36px;
    font-weight: normal;
    line-height: 1.21;
    color: #ffffff;
    margin: 0 0 31px;
}

.model_content_body {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.45;
    color: #ffd76c;
    margin: 0 0 16px;
}

.model_content_body2 {
    padding-bottom: 8px;
    position: relative;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.38;
    color: #ffffff;
    margin: 0 0 24px;
}

.model_content_body3 {
    font-size: 20px;
    font-weight: normal;
    line-height: 1.8;
    color: #ffd76c;
    margin: 0 0 16px;
}

.model_content_head .open-title {
    margin-bottom: 16px;
}

.content_wrapper .open-con {
    margin-bottom: 16px;
}

.content_wrapper .open-con b {
    font-size: 28px;
    font-weight: 900;
    color:#fff;
}

.content_wrapper .open-con2 {
    position:relative;
}

.content_wrapper .open-con2:last-child {
    font-family: "NotoSansSC";
    padding-top:31px;
    font-size:20px;
    font-weight:bold;
    color:#ffd76c;
    position:relative;
    margin: 24px 0 37px;
    margin-bottom: 0;
}

.angpow-balance {
    color:#fff;
}

.campaign-end .content_wrapper {
    top: 37%;
}

.campaign-end .model_content_head {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    margin: 0 0 24px;
}

.campaign-end .content-white {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0 0 24px;
}

.campaign-end .content-white span {
    font-weight: 900;
    color: #ffd76c;
}

.campaign-end .button-end {
    margin: 32px auto 0;
}

.span1 {
    font-weight: 900;
}

.popupbutton {
    background-image: linear-gradient(to bottom, #fbebaa, #ffb02c);
    height: 48px;
    left: 50%;
    border-radius: 24px;
    border: 0;
    font-family: "NotoSansSC", sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.45;
    color: #de2026;
    padding: 7px 30px 12px;
    display: block;
    margin: 63px auto 0;
}

.popupbutton a {
    text-decoration: none;
    color: #de2026;
}

.bold {
    font-weight: bold;
}

.close {
    font-size: 28px;
    color: #ffd76c;
    position: absolute;
    top: -8px;
    right: -34.8px;
    transition: color .3s ease-in-out;
}

.close:hover {
    color: cyan;
    cursor: pointer;
}

/* blessing popup */
.modal_blessing {
    top: 0;
}

.modal_blessing .content_wrapper {
    padding: 32px 0 0;
}

.modal_blessing .content_wrapper label {
    max-height: fit-content;
}

.modal_blessing .close {
    top: -34.8px;
    right: -74.2px;
    transform: translate(-100%, -100%);
}

.modal_blessing .blessing_close {
    top: 32px;
}

.divbox {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    font-family: "NotoSansSC", sans-serif;
    font-weight: normal;
    text-align: center;
    padding: 52px 36px 0;
}

.divbox > div {
    padding: 32px 0;
}

.divboxmain {
    position: relative;
    font-size: 48px;
    line-height: 1.21;
    color: #ffd76c;
    flex: 1;
    border: 2px solid #ffffff;
}

.divboxmain::before,
.divboxmain::after {
    content: "";
    width: 36px;
    height: 36px;
    background: #ffffff url("https://cdn.dootech.io/doo-prime-cny/Blessings/SVG/icon-popup13-coin.svg") no-repeat scroll center / 20px;
    position: absolute;
    top: calc(50% - 18px);
}
.divboxmain::before {left: -18px;background-color: rgb(177, 0, 32);}
.divboxmain::after {right: -18px;background-color: rgb(153, 0, 7);}

.divboxleft,
.divboxright {
    font-size: 32px;
    line-height: 1.19;
    color: #ffffff;
    flex: 0 0 30%;
    border: 2px solid #ffd76c;
}
.divboxleft {border-right: 0;}
.divboxright {border-left: 0;}

.blessingbody1 {
    font-size: 32px;
    font-weight: normal;
    line-height: 0.97;
    color: #ffffff;
    margin: 0 0 50px;
}
.blessingbody1 > .span1 {
    color:#ffd76c;
}

.blessingbody2 {
    font-size: 24px;
    font-weight: normal;
    line-height: 1.5;
    color: #ffffff;
    margin: 0 0 40px;
}

.blessingbody3 {
    font-size: 24px;
    font-weight: normal;
    line-height: 1.5;
    color: #ffd76c;
}

/* open angpau popup */
.modal_content_open {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
}

.modal_open .content_wrapper {
    bottom: 0;
    position: absolute;
    top: 30%;
    left: 0;
}

.modal_open .model_content_body2::before {
    display: none;
}

.modal_open .firework {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.modal_open .firework img {
    opacity: 0.6;
    width: 100%;
    height: 100%;
}

/* info popup */
.modal_info p.first {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.45;
    text-align: center;
    color: #ffffff;
    margin-bottom: 28px;
}

@media screen and (min-width: 720px) {
    .close {
        right: -74.2px;
    }
}

@media screen and (max-width: 580px) {
    .close {
        top: -32px;
        right: 0;
    }

    .modal_content {
        width: 100%;
    }

    .content_wrapper {
        top: 42%;
        padding: 16px 0;
    }
    
    .model_content_body {
        font-size: 16px;
        margin: 0 0 8px;
    }
    
    .model_content_body2 {
        font-size: 14px;
        margin: 0 0 12px;
        padding: 0 0 12px;
    }
    
    .model_content_body3 {
        font-size: 16px;
        margin: 0 0 8px;
    }

    .popupbutton {
        bottom: 32px;
        height: 40px;
        font-size: 16px;
        padding: 8px 30px;
    }

    /* info popup */
    .modal_info p.first {
        font-size: 16px;
    }

    .modal_wrapper img {
        max-width: 404px;
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    /* blessing popup */
    .modal_blessing .content_wrapper {
        top: 34%;
        padding: 32px 0 0;
    }

    .modal_blessing .modal_content {
        padding: 60px 20px 20px;
    }

    .modal_blessing .blessing_close {
        transform: translate(-100%,-100%);
        top: -7px;
        right: -19px;
    }

    .divbox {
        padding: 24px 16px 0;
    }

    .divbox > div {
        padding: 24px 0;
    }

    .divboxmain {
        font-size: 24px;
    }

    .divboxleft,
    .divboxright {
        font-size: 18px;
    }

    .blessingbody1 {
        font-size: 24px;
        margin: 0 0 32px;
    }

    .blessingbody2 {
        font-size: 18px;
        margin: 0 0 24px;
    }

    .blessingbody3 {
        font-size: 18px;
    }
}
